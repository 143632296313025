@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/font/CircularStd-Book.woff2') format('woff2'),
    url('./assets/font/CircularStd-Book.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./assets/font/CircularStd-Medium.woff2') format('woff2'),
    url('./assets/font/CircularStd-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/font/CircularStd-Bold.woff2') format('woff2'),
    url('./assets/font/CircularStd-Bold.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Circular Std', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
