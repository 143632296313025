.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  flex: 0 1 60px;
}

.header nav {
  height: 100%;
  padding: 0 60px;
}

.header img {
  vertical-align: middle;
}

.header a {
  color: #fff !important;
  text-decoration: none;
  vertical-align: middle;
}

.main {
  position: relative;
  overflow-x: hidden;
  flex: 1 0 auto;
  padding-bottom: 60px;
}

.footer {
  flex: 0 1 auto;
}

strong {
  font-size: 1.25rem;
  font-weight: bolder;
}
