.logo {
  height: 40px;
  margin-left: -8px;
  opacity: 0.5;
}

.list {
  list-style: none;
  padding: 0;
}

.listItem:not(:last-child) {
  margin-bottom: 12px;
}
