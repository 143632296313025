form[id^="snail-mail-form"] fieldset {
  border: none;
}

form[id^="snail-mail-form"] div[role^="alert"]>div {
  display: contents;
}

form[id^="snail-mail-form"] div[role^="alert"] p {
  margin: unset;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

form[id^="snail-mail-form"] div[role^="alert"] button {
  background: rgba(0, 0, 0, 0.5);
}

.snail img,
.snail::before {
  position: absolute;
  animation-duration: 180s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.snail img {
  width: 50px;
  left: -50px;
  bottom: 0;
  animation-name: move-snail;
}

.snail::before {
  content: '';
  height: 3px;
  left: 1px;
  bottom: 3px;
  animation-name: move-trail;
  background: #ffedc6;
}

@keyframes move-snail {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes move-trail {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
